import React, { useState } from 'react'
import styled from 'styled-components'
import { Copy } from 'react-feather'
import copy from 'copy-to-clipboard'

import { VerifyAuthCode } from './VerifyAuthCode'
import { Label } from '../shared/Field'

const CodeTag = styled.span`
  background-color: #f0f2f6;
  color: #4c517f;
  font-family: monospace;
  padding: 0.4em 0.6em;
`

export const CopyButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4c517f;
  transition: 0.3s all;

  &:hover {
    filter: brightness(70%) hue-rotate(-2deg);
  }

  &:active {
    filter: brightness(120%) hue-rotate(2deg);
  }
`

export const PurpleButton = styled.div`
  background-color: #6152cb;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  padding: 1em 4em;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    filter: brightness(95%) hue-rotate(2deg);
  }

  &:active {
    filter: brightness(105%) hue-rotate(2deg);
  }
`

const GuestRegWrap = styled.div`
  color: #3b3c44;

  p {
    line-height: 1.65em;
    font-size: 15px;
  }
`

export default function GuestRegistration({ otpSecretKey, email, qrImageUrl }) {
  const [copied, setCopied] = useState(false)
  const [showVerify, setShowVerify] = useState(false)

  const handleClickCopy = () => {
    copy(otpSecretKey)
    setCopied(true)
  }

  if (showVerify) return <VerifyAuthCode mode="mfa" />

  return (
    <GuestRegWrap>
      <p>
        Register a multi-factor authentication device with Google Authenticator or 1Password
        by scanning the QR code below.
      </p>

      <div style={{ textAlign: 'center' }}>
        <form>
          <Field name="email" label="Email Address" disabled value={email} />
        </form>
        <img src={qrImageUrl} />

        <p style={{ color: '#3B3C44', fontSize: 16, fontWeight: 600, marginTop: 6 }}>
          Or enter the following code manually:
        </p>

        <div
          onClick={handleClickCopy}
          style={{
            cursor: 'pointer',
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            columnGap: '8px',
          }}
        >
          <CodeTag>{otpSecretKey}</CodeTag>
          <CopyButton>
            <Copy size={20} style={{ marginRight: '6px' }} />
            <span style={{ fontWeight: 700, textTransform: 'uppercase' }}>
              {copied ? 'Copied!' : 'Copy'}
            </span>
          </CopyButton>
        </div>

        <PurpleButton style={{ marginTop: '1em' }} onClick={() => setShowVerify(true)}>
          Next
        </PurpleButton>
      </div>
    </GuestRegWrap>
  )
}

const FieldWrap = styled.div`
  text-align: left;
`

const Input = styled.input`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  background-color: ${(props) => (props.error ? '#D13939' : '#f7f7f7')};
  border-color: #d3d5e9;
  color: #858585;
  font-weight: 500;
  border-radius: 8px;
  padding: 0.8em 1em;
  height: auto;
`

const FieldError = styled.div`
  background-color: #fbefef;
  color: #d13939;
  border: 1px solid #d13939;
  border-radius: 6px;
  padding: 1em;
  font-weight: 500;
`

export function Field({ name, label, type, value, error, onChange, ...rest }) {
  const [val, setValue] = useState(value)
  const handleChange = (e) => {
    const newVal = e.target.value
    setValue(newVal)
    onChange && onChange(newVal)
  }

  return (
    <FieldWrap className={name} style={{ textAlign: 'left' }}>
      <Label style={{ color: '#3B3C44' }}>{label}</Label>
      <Input
        name={name}
        error={!!error}
        value={val}
        type="text"
        onChange={handleChange}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md py-3"
        {...rest}
      />
      {error && <FieldError style={{ marginTop: '1em' }}>{error}</FieldError>}
    </FieldWrap>
  )
}
