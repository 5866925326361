import React from 'react'

import Api from '../utils/Api'

export default function OrganizationSettings({ chatWorkspace, slackUrl }) {
  const clickedUninstall = (e) => {
    e.preventDefault()

    if (
      window.confirm('Are you sure you want to uninstall the Sharesecret slack extension?')
    ) {
      Api.util.post('/slack/uninstall').then((res) => {
        window.location.reload()
      })
    }
  }

  return (
    <div className="slack-install-panel">
      <div className="slack-install-status my-2">
        <strong>Slack Extension Status: </strong>
        <span className={chatWorkspace ? 'installed' : 'not-installed'}>
          {chatWorkspace ? `Installed to ${chatWorkspace.name}` : 'Not  Installed'}
        </span>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }} className="slack-buttons">
        <a
          href={slackUrl}
          className="btn btn-blue slack-reconnect bg-blue-500 text-white rounded-md px-8 py-3 border border-transparent shadow-sm text-base font-medium hover:bg-blue-600 mr-3"
        >
          {chatWorkspace ? 'Reconnect Slack' : 'Install Slack Extension'}
        </a>

        {chatWorkspace && (
          <button
            className="btn slack-uninstall bg-red-500 text-white rounded-md px-8 py-3 border border-transparent shadow-sm text-base font-medium hover:bg-red-600"
            onClick={clickedUninstall}
          >
            Uninstall Slack Extension
          </button>
        )}
      </div>
    </div>
  )
}
