import React from 'react'
import styled from 'styled-components'

export const CopyButton = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #4c517f;
  transition: 0.3s all;

  &:hover {
    filter: brightness(70%) hue-rotate(-2deg);
  }

  &:active {
    filter: brightness(120%) hue-rotate(2deg);
  }
`

export const PurpleButtonBase = styled.button`
  background-color: #6152cb;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 1em;
  padding: 1.1em 4em;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s linear;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(95%) hue-rotate(2deg);
  }

  &:active {
    filter: brightness(105%) hue-rotate(2deg);
  }

  &.loading {
    pointer-events: none;
    opacity: 0.7;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const PurpleGradientButton = styled(PurpleButtonBase)`
  background-color: none;
  background: linear-gradient(180deg, #6b5bd2 2.41%, #353694 133.61%);
  border: 1px solid #5246a9;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    background: linear-gradient(180deg, #6b5bd2 2.41%, #353694 110.61%);
    transition: opacity 0.3s;
  }

  &:hover::before {
    opacity: 1;
  }
`

export const Spinner = () => (
  <svg
    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
)

export const PurpleButton = ({ loading, content, children, className, ...rest }) => {
  let classes = className
  if (loading) classes += ' loading'
  return (
    <PurpleButtonBase className={classes} {...rest}>
      {loading && <Spinner />}
      {content || children}
    </PurpleButtonBase>
  )
}

export const SmallButton = styled(PurpleButton)`
  padding: 0.8em 1em;
`

export const PurpleButtonLink = styled.a(PurpleButton)
