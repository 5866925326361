import { CSSTransition, Transition } from 'react-transition-group'

import './setup.scss'

import CompleteSignup from './CompleteSignup'

class SetupHome extends React.Component {
  state = {step: 1}

  changeStep = (by) => {
    this.setState({step: (this.state.step + by)})
  }

  render() {
    const step = this.state.step
    return (
      <div>
        <Fade in={step === 1}>
          <CompleteSignup onFinish={this.changeStep.bind(null, 1)} {...this.props} />
        </Fade>
        <Fade in={step === 2}>
          <GetStarted onBack={this.changeStep.bind(null, -1)} />
        </Fade>
      </div>
    )
  }

  renderStep() {
    const step = this.state.step
    switch (step) {
      case 1:
        return <Fade in={step === 1}><CompleteSignup onFinish={this.changeStep.bind(null, 1)} {...this.props} /></Fade>
        break;
      default:
        return <Fade in={step === 2}><GetStarted onBack={this.changeStep.bind(null, -1)} /></Fade>
    }
  }
}

const Fade = ({children, in: inProp}) => {
  return <CSSTransition in={inProp}
    timeout={3000}
    classNames="fade">
    <div className={`setup-flow step-active-${inProp}`}>
      {children}
    </div>
  </CSSTransition>
}

const GetStarted = ({onBack}) => (
  <div className="get-started">
    <h1>Get Started</h1>
    <button onClick={() => window.location.href = '/dashboard'}>Go to Dashboard</button>
    <button onClick={onBack}>Back</button>
  </div>
)

export default SetupHome
