import React from 'react'
import styled from 'styled-components'

const Note = styled.span`
  color: #737373;
  font-weight: normal;
  font-size: 13px;
`

export default function ({ className, children }) {
  return <Note className={className}>{children}</Note>
}
