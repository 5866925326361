import React, { useState } from 'react'
import copy from 'copy-to-clipboard'
import { Copy } from 'react-feather'
import { Plus } from 'heroicons-react'

export default function SecretUrl({ url = 'heres a url', onNew }) {
  const [copied, setCopied] = useState(false)
  const handleClickCopy = () => {
    copy(url)
    setCopied(true)
  }

  let copyButtonClasses =
    'bg-ss-purple-600 text-white rounded-md px-6 py-2 border border-transparent shadow-sm font-medium hover:bg-indigo-800 flex items-center space-x-2 justify-center w-60'
  if (copied) {
    copyButtonClasses += ' disabled:opacity-50'
  }

  return (
    <div className="text-secret-form">
      <div className="field">
        <textarea
          ref={(e) => e && e.select()}
          type="text"
          defaultValue={url}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      <div className="flex my-4 items-center space-x-4">
        <button
          onClick={handleClickCopy}
          className={copyButtonClasses}
          disabled={copied ? 'disabled' : undefined}
        >
          <Copy size={16} style={{ marginRight: '6px' }} />
          <span style={{ fontWeight: 700 }}>
            {copied ? 'URL Copied' : 'Copy Secret URL'}
          </span>
        </button>
        {copied && (
          <p className="italic text-gray-600">Secret URL copied to your clipboard.</p>
        )}
      </div>

      <hr />
      <button
        className="bg-ss-purple-500 text-white rounded-md mt-4 px-6 py-2 border border-transparent shadow-sm text-base font-medium hover:bg-indigo-800 flex items-center space-x-2 justify-center"
        onClick={onNew}
      >
        <span>
          <Plus size={20} />
        </span>
        <span>Share Another Secret</span>
      </button>
    </div>
  )
}
