import React, { Component } from 'react'
import PropTypes from 'prop-types'
import times from 'lodash.times'
import { Sliders } from 'react-feather'
import { LockClosedOutline } from 'heroicons-react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import FileUploader from './FileUploader'
import SecretUrl from './SecretUrl'
import { Api, ErrorReporter } from '../utils/'
import { Field } from '../shared/Field'
import Textarea from '../shared/Textarea'
import Checkbox from '../shared/Checkbox'
import { NewErrorMessage } from '../shared/ErrorMessage'
import { PurpleGradientButton } from '../shared/Button'
import Note from '../shared/Note'
import './SecretForm.scss'

const MB_DIVISOR = 1000000

const IndicatorsContainer = () => {
  return <div />
}
export default class SecretForm extends Component {
  state = {
    exp_days: '1',
    secret: '',
    loading: false,
    showResult: false,
    one_time_use: false,
    guest_mfa: false,
    guest_email: '',
    error: null,
    shared_with: [],
  }

  changeField = (e) => this.setState({ [e.target.name]: e.target.value })
  changeCheckbox = (e) => this.setState({ [e.target.name]: e.target.checked })

  renderUploader() {
    return (
      <FileUploader
        onFilePicked={this.saveFile}
        onFileRejected={this.fileDropRejected}
        onDrop={this.filePicked}
        loading={this.state.loading}
        organization={this.props.organization}
      />
    )
  }

  handleSharedWithChange = (val) => {
    if (val) {
      const vals = val.map((v) => v.value)
      return this.setState({ shared_with: vals })
    }
    this.setState({ shared_with: [] })
  }

  searchUsers = (val) => {
    return new Promise((resolve, reject) => {
      Api.Users.search(val)
        .then((res) => {
          if (res.body && res.body.users) {
            return resolve(res.body.users.map((u) => ({ value: u.email, label: u.email })))
          }
          return resolve([])
        })
        .catch((err) => {
          console.error('err', err)
          reject(err)
        })
    })
  }

  render() {
    const { type, secretRequestId, organization, subscription } = this.props
    const { require_global_guest_mfa } = organization
    const plan = subscription && subscription.plan
    const plan_features = plan && plan.plan_features
    const hasGuestSecret =
      plan_features && plan_features.filter((pf) => pf.name === 'guest_secret').length === 1
    const {
      secret,
      exp_days,
      loading,
      showResult,
      one_time_use,
      guest_mfa,
      guest_email,
      error,
    } = this.state

    if (showResult) {
      return this.showSecret()
    }

    return (
      <form className="text-secret-form" autoComplete="off">
        {/* <h1>Share a {type === 'text' ? 'Text Secret' : 'File'}</h1> */}

        {error && (
          <div className="mb-4">
            <NewErrorMessage error={error} />
          </div>
        )}
        <div className={`field ${type === 'text' ? 'field-inline' : ''}`}>
          {type === 'text' ? (
            <Textarea
              value={secret}
              name="secret"
              placeholder="Enter a password, or any other information you need to share securely."
              onChange={this.changeField}
            />
          ) : (
            this.renderUploader()
          )}
        </div>

        {!secretRequestId && (
          <React.Fragment>
            <div className="field field-inline">
              <label>Expires after</label>
              {this.ttlSelect()}
              <span className="note">
                This secret will be destroyed after {exp_days}{' '}
                {exp_days > 1 ? 'days' : 'day'}.
              </span>
            </div>

            <div
              className="items-start space-x-2 field field-inline"
              style={{ alignItems: 'start' }}
            >
              <Checkbox
                name="one_time_use"
                checked={one_time_use}
                onChange={this.changeCheckbox}
                style={{ marginTop: 3 }}
              />
              <div className="block">
                <label className="leading-none">One-time use</label>
                <div className="pl-0 note" style={{ paddingLeft: 0 }}>
                  The secret will be destroyed after it's accessed once.
                </div>
              </div>
            </div>

            <hr className="mb-4" />

            <div className="flex flex-wrap items-center mb-4 space-x-2 font-semibold text-gray-500">
              <Sliders size={16} />
              <h4>Access Control</h4>
            </div>

            <div className="field">
              <label className="block mb-2">Share with</label>
              <div className="space-y-4">
                <AsyncSelect
                  name="shared_with2"
                  isMulti
                  loadOptions={this.searchUsers}
                  cacheOptions
                  isSearchable
                  components={{ IndicatorsContainer }}
                  placeholder="user@example.com"
                  onChange={this.handleSharedWithChange}
                  style={{ flexGrow: 2 }}
                  className="mb-3 share-with-select"
                  noOptionsMessage={() => 'Type an email address'}
                />

                <Note>
                  The secret link will be delivered via email to the recipient, and the
                  recipient will be required to login with the email address provided.
                </Note>
              </div>
            </div>

            <div className={`field`} disabled={!hasGuestSecret}>
              <div className="flex items-center mb-3">
                <label className={`block ${!hasGuestSecret && 'opacity-40'}`}>
                  Guest Email
                </label>
                {!hasGuestSecret && (
                  <a href="/subscription" className="opacity-100">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                      Pro Only
                    </span>
                    <span className="ml-2 text-xs font-medium text-blue-700">
                      UPGRADE NOW
                    </span>
                  </a>
                )}
              </div>

              <div className="flex items-center space-x-2">
                <Field
                  name="guest_email"
                  type="email"
                  autoComplete="off"
                  placeholder="guest@example.com"
                  disabled={!hasGuestSecret}
                  value={guest_email}
                  onChange={(value) => this.setState({ guest_email: value })}
                  className="rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
                <Note>Email address of someone outside your organization</Note>
              </div>
            </div>

            <div className="field">
              <div className="flex space-x-2 items-top">
                <Checkbox
                  name="guest_mfa"
                  checked={require_global_guest_mfa ? true : guest_mfa}
                  onChange={this.changeCheckbox}
                  disabled={require_global_guest_mfa}
                />
                <div>
                  <label className="block" style={{ width: 'auto' }}>
                    Require multi factor authentication
                  </label>
                  <Note>
                    Recipient must authenticate a second factor to access this secret.
                  </Note>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        {type === 'text' ? (
          <PurpleGradientButton
            className="flex items-center justify-center w-full px-8 py-4 space-x-2 text-lg font-semibold text-white border border-transparent rounded-md shadow-sm"
            onClick={this.submitSecret}
            disabled={loading}
          >
            <span>
              <LockClosedOutline size={20} />
            </span>
            <span>Encrypt Secret</span>
          </PurpleGradientButton>
        ) : (
          ''
        )}
      </form>
    )
  }

  ttlSelect() {
    const { exp_days, one_time_use } = this.state
    const { organization } = this.props
    let limit = 7
    if (organization.ttl_limit && organization.ttl_limit !== '') {
      limit = organization.ttl_limit
    }

    return (
      <select
        name="exp_days"
        className="inline-block py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={exp_days}
        onChange={this.changeField}
        disabled={one_time_use ? 'disabled' : undefined}
      >
        {times(limit).map((n) => (
          <option key={n + 1} value={n + 1}>
            {n + 1} {n + 1 > 1 ? 'days' : 'day'}
          </option>
        ))}
      </select>
    )
  }

  showSecret() {
    const { url } = this.state.result

    return <SecretUrl url={url} onNew={this.resetForm} />
  }

  resetForm = () => {
    this.setState({ secret: '', result: null, showResult: false, error: null })
  }

  handleSecretCreated = (secret) => {
    this.setState({ showResult: true, result: secret, loading: false, error: null })
  }

  submitSecret = (e) => {
    e.preventDefault()
    const { secret, guest_mfa, guest_email } = this.state
    const { secretRequestId, type } = this.props

    // form validation
    if (type === 'text' && (!secret || secret === '')) {
      return this.setState({ error: 'Please enter a secret.' })
    }

    if (guest_mfa && (!guest_email || guest_email === '')) {
      return this.setState({ error: 'Please enter a guest email address' })
    }

    const url = secretRequestId ? `/secret-requests/${secretRequestId}` : '/secrets'
    this.setState({ loading: true }, () => {
      Api.util
        .post(url, this.getData())
        .then((res) => {
          if (secretRequestId) {
            window.location.href = '/secret-requests/complete'
          } else {
            this.handleSecretCreated(res.body)
          }
        })
        .catch((err) => {
          if (err.response && err.response.body) {
            this.setState({
              loading: false,
              error: Object.values(err.response.body.errors).flat()[0],
            })
          } else {
            ErrorReporter.report(err)
            this.setState({ loading: false })
          }
        })
    })
  }

  saveFile = (files) => {
    this.setState({ loading: true }, () => {
      Api.Secret.upload(this.getData(), files)
        .then((res) => {
          this.handleSecretCreated(res.body)
        })
        .catch((err) => {
          ErrorReporter.report(err)
          if (err.response.body.errors) {
            this.setState({
              loading: false,
              error: err.response.body.errors,
            })
          }
        })
    })
  }

  /**
   * File was rejected, likely due to the file size.
   *
   * @param {Number} maxSize
   * @param {File} file
   */
  fileDropRejected = (maxSize, files) => {
    const file = files[0]
    const sizeInMB = file.size / MB_DIVISOR
    const maxSizeInMB = maxSize / MB_DIVISOR

    if (file.size > maxSize) {
      const msg = `Oops, the maximum file size is ${maxSizeInMB} MB, but you tried to upload a ${
        Math.round(sizeInMB * 100) / 100
      } MB file. If you need to share larger encrypted files, please contact support@sharesecret.co.`
      this.setState({ error: msg })
    } else {
      const msg =
        'Oops, an unexpected error occurred. The issue has been reported to our team.'
      ErrorReporter.report(new Error(msg), {
        fileSize: file.size,
        fileType: file.type,
      })
      this.setState({ error: msg })
    }
  }

  getData() {
    const { exp_days, secret, one_time_use, guest_mfa, guest_email, shared_with } =
      this.state
    let data = {
      secret,
      one_time_use,
      guest_mfa,
      guest_email,
      exp_days,
      shared_with,
    }
    return data
  }
}

SecretForm.propTypes = {
  type: PropTypes.string.isRequired, // text or file
  organization: PropTypes.object,
  secretRequestId: PropTypes.string,
}
