import React, { useState } from 'react'
import styled from 'styled-components'

export const FieldWrap = styled.div`
  text-align: left;
`

export const Input = styled.input`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  background-color: ${(props) => (props.error ? '#D13939' : '#fff')};
  border: 1px solid #d3d5e9;
  color: #1b1b1b;
  font-weight: 400;
  height: auto;
  box-shadow: none;

  &[disabled] {
    pointer-events: none;
    background-color: #f7f7f7;
  }
`

export const FieldError = styled.div`
  background-color: #fbefef;
  color: #d13939;
  border: 1px solid #d13939;
  border-radius: 6px;
  padding: 1em;
  font-weight: 500;
`

export const Label = styled.label`
  display: block;
  color: #3b3c44;
  font-size: 14px;
  font-weight: 600;
`

export const FieldNote = styled.div`
  color: #6a6b74;
  font-size: 13px;
  line-height: 1.5em;
`

export function Field({
  name,
  label,
  type,
  value,
  error,
  onChange,
  input,
  style,
  ...rest
}) {
  const [val, setValue] = useState(value)
  const handleChange = (e) => {
    const newVal = e.target.value
    setValue(newVal)
    onChange && onChange(newVal, e)
  }

  return (
    <FieldWrap className={name} style={{ textAlign: 'left', ...style }}>
      <Label htmlFor={name} style={{ color: '#3B3C44' }}>
        {label}
      </Label>
      {input || (
        <Input
          name={name}
          id={name}
          error={!!error}
          value={val}
          type="text"
          onChange={handleChange}
          {...rest}
        />
      )}
      {error && <FieldError style={{ marginTop: '1em' }}>{error}</FieldError>}
    </FieldWrap>
  )
}
