import { Api } from '../utils'

class CompleteSignup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      company_name: (props.company_name || ''),
      errors: {},
      saving: false
    }
  }

  changeField = (e) => {
    this.setState({[e.target.name] : e.target.value})
  }

  validateInputs() {
    const {company_name} = this.state
    var errors = {}

    if(company_name === '') errors.company_name = 'Company is required'

    this.setState({errors})

    return _.isEmpty(errors)
  }

  clickedSignUp = (e) => {
    e.preventDefault()
    if (this.validateInputs()) {
      this.setState({saving: true}, this.completeSetup)
    }
  }

  completeSetup = () => {
    Api.Setup.complete(this.state).then(res => {
      this.props.onFinish(this.state)
    }).catch(err => {
      console.error(err)
      return
    })
  }

  render() {
    const { errors, saving } = this.state

    return (
      <div className="complete-signup step">
        <h1>Complete Account Setup</h1>
        <p>Verify and complete your account details below.</p>
        <form>
          <TextField name="company_name"
            label="Company Name"
            value={this.state.company_name}
            onChange={this.changeField}
            error={errors.company_name} />

          <div>
            <button disabled={saving} onClick={this.clickedSignUp}>Save</button>
          </div>
        </form>
      </div>
    )
  }
}

CompleteSignup.propTypes = {
  onFinish: PropTypes.func.isRequired
}

class TextField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {value: props.value}
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({[e.target.name] : e.target.value})
    this.props.onChange && this.props.onChange(e)
  }

  render() {
    const props = this.props
    const label = props.label || props.name
    const error = props.error

    var klasses = "field"
    if(error) klasses += " error"

    const newProps = _.omit(this.props, 'label', 'error', 'onChange')

    return (
      <div className={klasses}>
        <label>{label}</label>
        <input type="text"
          value={this.state.value}
          onChange={this.onChange}
          name={props.name}
          {...newProps} />
        <div className="error-message">{error}</div>
      </div>
    )
  }
}

TextField.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string
}

export default CompleteSignup
