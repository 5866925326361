import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PurpleButton } from '../shared/Button'

import Label from '../shared/Label'
import CopyValue from '../shared/CopyValue'
import MetadataUrlForm from './MetadataUrlForm'

import Api from '../utils/Api'

export default class SamlSSOSettings extends Component {
  static propTypes = {
    token: PropTypes.string,
    saml_org_id: PropTypes.string,
  }
  state = { token: this.props.token || '', loading: false, url: '' }

  render() {
    const { token, loading } = this.state
    const { saml_org_id } = this.props
    const showGenerate = !token || token === ''

    return (
      <div>
        <div className="mb-2 ss-field">
          <Label>iDP API Token</Label>
          <div>
            {token && <CopyValue value={token} />}
            <div>
              {showGenerate && (
                <PurpleButton onClick={this.clickedGenerateToken} loading={loading}>
                  Generate Token
                </PurpleButton>
              )}
            </div>
          </div>
          <div className="text-sm note text-color-note" style={{ paddingTop: 10 }}>
            The iDP API Token is used to enable SCIM provisioning with your SSO identity
            provider.{' '}
            <a
              href="https://support.sharesecret.co/article/7-okta-saml-sso-configuration"
              target="_blank"
            >
              Learn more
            </a>
          </div>
        </div>

        <div className="mb-2 ss-field">
          <Label>SAML Tenant ID</Label>
          <div>
            <CopyValue value={saml_org_id} />
          </div>
          <div className="text-sm note text-color-note">
            The SAML Tenant ID is used to configure your organization's SAML integration.
            You need to paste this value into the Default Relay State field of the
            Sharesecret SAML app settings.{' '}
            <a
              href="https://support.sharesecret.co/article/7-okta-saml-sso-configuration"
              target="_blank"
            >
              Learn more
            </a>
          </div>
        </div>

        <div className="mb-2">
          <MetadataUrlForm url={this.props.url} />
        </div>
      </div>
    )
  }

  clickedRegenerate = () => {
    if (
      window.confirm(
        'Are you sure? Regenerating the token will invalidate the existing token.'
      )
    ) {
      this.clickedGenerateToken()
    }
  }

  clickedGenerateToken = (e) => {
    e.preventDefault()
    this.setState({ loading: true }, () => {
      Api.OrgTokens.generate()
        .then((res) => {
          this.setState({ token: res.body.token, loading: false })
        })
        .catch((err) => {
          this.setState({ loading: false })
          console.error(err)
        })
    })
  }
}
