import request from 'superagent'

const Api = {
  Secret: {
    create: (data) => Api.util.post('/secrets', data),
    upload: (data, files) => Api.util.upload('/secrets', data, files),
    decrypt: (id) => Api.util.post(`/secrets/${id}/decrypt`, null),
  },

  OrgTokens: {
    generate: () => Api.util.post('/organization/token'),
  },

  User: {
    update: (data, cb) => Api.util.patch('/users', data, cb),
    invite: (data) => Api.util.post('/users/invite', data),
  },

  Setup: {
    complete: (data) => Api.util.post('/setup/complete', data),
  },

  Subscription: {
    update: (data) => Api.util.patch('/subscription', data),
    delete: () => Api.util.delete('/subscription'),
  },

  PaymentCard: {
    create: (data) => Api.util.post('/payment_cards', data),
  },

  Users: {
    search: (query) => Api.util.get('/users/search', { q: query }),
  },

  util: {
    get: (url, params) => {
      return request
        .get(url)
        .query(params)
        .send()
        .set({ 'X-CSRF-Token': Api.util.csrfToken() })
        .accept('json')
        .type('json')
    },
    post: (url, data) => {
      return request
        .post(url)
        .send(data)
        .set({ 'X-CSRF-Token': Api.util.csrfToken() })
        .accept('json')
        .type('json')
    },

    patch: (url, data, cb) => {
      const req = request
        .patch(url)
        .send(data)
        .set({ 'X-CSRF-Token': Api.util.csrfToken() })

      return cb ? req.end(cb) : req
    },

    upload: (url, data, files) => {
      let req = request.post(url).accept('json').attach('file', files[0])
      for (let key in data) {
        req = req.field(key, data[key])
      }
      return req
    },

    delete: (url) => {
      return request.delete(url).set({ 'X-CSRF-Token': Api.util.csrfToken() })
    },

    csrfToken: () => document.querySelector('meta[name=csrf-token]').content,
  },
}

export default Api
