import React, { Component } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { SmallButton as Button } from '../shared/Button'

import { Input } from '../shared/Field'

export default class CopyValue extends Component {
  state = { copied: false }
  textInput = null

  static propTypes = {
    value: PropTypes.string,
  }

  render() {
    const { copied } = this.state
    const { value } = this.props

    return (
      <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
        <Input
          type="text"
          className="disabled-value shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-6/12 border-gray-300 rounded-md py-3 pointer-events-none"
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          value={value}
          style={{ minWidth: 300, marginRight: 10 }}
          ref={(e) => (this.textInput = e)}
          onFocus={this.focusInput}
          onChange={() => null}
        />

        <div style={{ marginLeft: 10 }}>
          <Button onClick={this.clickedCopyToken}>{copied ? 'Copied!' : 'Copy'}</Button>
        </div>
      </div>
    )
  }

  clickedCopyToken = (e) => {
    e.preventDefault()
    this.focusInput()
    copy(this.props.value)
    this.setState({ copied: true })
  }

  focusInput = () => {
    this.textInput && this.textInput.select()
  }
}
