
class ContactSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {search: ''}

    this.changeText = this.changeText.bind(this)
    this.gapiLoaded = this.gapiLoaded.bind(this)
    this.updateSigninStatus = this.updateSigninStatus.bind(this)
  }

  componentWillMount() {
    window.addEventListener('gapi:load', this.gapiLoaded)
  }

  componentWillUnmount() {
    window.removeEventListener('gapi:load', this.gapiLoaded)
  }

  gapiLoaded() {
    console.log('gapi', gapi)
    gapi.load('client:auth2', () => {
      console.log('auth callback')
      gapi.client.init({
        apiKey: 'AIzaSyBY8lnLT8qvEBb994mYUP5X0_VhZ-ikydI',
        clientId: '636151210174-v9o94o0omgp7beaa7am31n37uqu5sspr.apps.googleusercontent.com',
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"],
        scope: 'https://www.googleapis.com/auth/contacts.readonly'
      }).then(() => {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);

        // Handle the initial sign-in state.
        this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        // authorizeButton.onclick = handleAuthClick;
        // signoutButton.onclick = handleSignoutClick;
      });
    })
  }

  updateSigninStatus(isSignedIn) {
    gapi.client.people.people.connections.list({
           'resourceName': 'people/me',
           'pageSize': 10,
           'personFields': 'names,emailAddresses',
         }).then(function(response) {
           console.log('response', response)
           var connections = response.result.connections;
           appendPre('Connections:');

           if (connections.length > 0) {
             for (i = 0; i < connections.length; i++) {
               var person = connections[i];
               if (person.names && person.names.length > 0) {
                 appendPre(person.names[0].displayName)
               } else {
                 appendPre("No display name found for connection.");
               }
             }
           } else {
             appendPre('No upcoming events found.');
           }
         });
  }

  changeText(e) {

  }

  render() {
    return (
      <div>
        <h3>2. Add People</h3>
        <input type="text"
          value={this.state.search}
          name="search"
          onChange={this.changeText} />
      </div>
    )
  }
}

export default ContactSearch
