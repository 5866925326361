import React from 'react'

import { SmallButton as Button } from '../Button'
import { Input } from '../Field'
import Errors from './FormErrors'
import FormErrors from './FormErrors'
import Label from '../Label'

export default function Field({
  note,
  label,
  buttonDisabled,
  button,
  onSubmit,
  loading,
  className,
  error,
  ...rest
}) {
  let classes = 'ss-field'
  if (className) classes += ` ${className}`

  return (
    <div className={classes}>
      <Label>{label}</Label>
      <div className="input-cont mb-2" style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          error={!!error}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-gray-800 block w-6/12 border-gray-300 rounded-md py-3"
          {...rest}
        />
        {button && (
          <Button
            content={button}
            onClick={onSubmit}
            className="inline-button ml-2"
            disabled={buttonDisabled}
            onSubmit={onSubmit}
            loading={loading}
          />
        )}
      </div>
      {note && <div className="note text-sm text-color-note">{note}</div>}
      {error && <FormErrors>{error}</FormErrors>}
    </div>
  )
}
