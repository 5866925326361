import PropTypes from 'prop-types'
import React from 'react'

import { SecretForm } from '../Secrets'
import Card from '../shared/Card'
// import CompleteSignup from '../setup/CompleteSignup'

class DashboardHome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      company_name: props.organization && props.organization.name,
      tab: 'text',
    }
  }

  finishSignup = (state) => {
    window.location.reload()
  }

  render() {
    const { tab } = this.state
    const { organization, users, subscription } = this.props
    const isFileSharingDisabled =
      organization.disabled_plan_features.includes('file_sharing')

    // if (!company_name) {
    //   return <CompleteSignup onFinish={this.finishSignup} />
    // }

    return (
      <Card>
        <div className="inline-grid grid-cols-2 mb-4 gap-x-4">
          {this.tab('text', 'Share Text')}
          {!isFileSharingDisabled && this.tab('file', 'Share File')}
        </div>
        <div className="content">
          <div className="dashboard-cont">
            <SecretForm
              type={tab}
              organization={organization}
              subscription={subscription}
            />
          </div>
        </div>
      </Card>
    )
  }

  tab(val, label) {
    const { tab } = this.state
    let classes = 'tab text-indigo-800 cursor-pointer'
    if (tab === val) classes += ' active underline font-semibold'

    return (
      <div className={classes} onClick={() => this.setState({ tab: val })}>
        {label}
      </div>
    )
  }
}

DashboardHome.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default DashboardHome
