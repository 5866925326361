import React from 'react'
import { Message } from 'semantic-ui-react'
import flatten from 'lodash.flattendeep'
import isString from 'lodash.isstring'

const FormErrors = ({errors, children}) => {
  if (children) {
    return <Message error>
      {children}
    </Message>
  }

  const values = isString(errors) ? [errors] : Object.values(errors)
  return <Message error>
    <ul style={{paddingLeft: 0}}>
      {flatten(values).map((error, i) => (
        <li key={`error-${i}`}>{error}</li>
      ))}
    </ul>
  </Message>
}

export default FormErrors
