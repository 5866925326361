import React from 'react'
import { Info } from 'react-feather'
import styled from 'styled-components'

const InfoNoticeWrap = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  padding: 1em;
  border: 1px solid #90b9ed;
  border-radius: 8px;
  background-color: #eff6ff;
  color: #3266a8;
`

export const InfoNotice = function ({ children }) {
  return (
    <InfoNoticeWrap>
      <div>
        <Info color="#3D7ED1" />
      </div>
      <div>{children}</div>
    </InfoNoticeWrap>
  )
}
