import React from 'react'
import Dropzone from 'react-dropzone'

import { PurpleButton as Button } from '../shared/Button'

const dropStyle = {
  border: 0,
  background: '#F8F8F8',
  border: '1px solid #F1ECEC',
  borderRadius: 6,
  padding: 20
}

const MAX_SIZE = 1000000 // 1mb

function sizeInMB(size) {
  return size / 1000000
}

const FileUploader = ({onFilePicked, onFileRejected, loading, organization}) => {
  const { upload_limit } = organization
  const maxSize = upload_limit || MAX_SIZE

  return <Dropzone onDropAccepted={onFilePicked}
    onDropRejected={onFileRejected.bind(null, maxSize)}
    multiple={false}
    style={dropStyle}
    maxSize={maxSize}>
    <div>
      <div className='mb-2'>Pick a file or drag and drop one here.</div>
      <Button onClick={(e) => e.preventDefault()} loading={loading}>
        Select a File
      </Button>

      <div className="note" style={{paddingLeft: 0, paddingTop: 10}}>
        <em>{sizeInMB(maxSize)} MB max file size.</em>
      </div>
    </div>
  </Dropzone>
}

export default FileUploader
