import React from 'react'
import { Check } from 'heroicons-react'
import { PurpleButton as Button } from '../shared/Button'

import './Plan.scss'

const Plan = ({
  plan,
  onPick,
  active,
  upgrading,
  maus,
  enterprise = false,
  buttonLabel = 'Upgrade Now',
}) => {
  const onClickPlan = () => onPick(plan)

  return (
    <div className={`plan ${plan.header_class ? `${plan.header_class} rounded` : ''}`}>
      <div className="plan-title">{plan.name}</div>
      <div className="plan-summary">{plan.description}</div>
      <div className="price-wrap">
        {enterprise && <div className="plan-price">Contact for Pricing</div>}
        {!enterprise && (
          <div className="plan-price">
            <span className="plan-dollar">$</span>
            {plan.price / 100}

            <span className="per-month">
              {plan.licensed
                ? '/ active user / month'
                : plan.frequency === 'monthly'
                ? 'mo'
                : 'yr'}
              {plan.min_amount && (
                <div className="min-amount">*min ${plan.min_amount / 100}/mo</div>
              )}
            </span>
          </div>
        )}

        {maus && maus > 0 ? (
          <div style={{ marginTop: '1em' }}>
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>
              Est. monthly cost: ${maus * (plan.price / 100)} / mo
            </div>
            <div style={{ fontSize: 12, lineHeight: '1.5em', marginTop: '6px' }}>
              Based on your usage of <strong>{maus} users</strong> during the trial period.
            </div>
          </div>
        ) : null}
      </div>

      <div className="button-wrap">
        <Button
          onClick={onClickPlan}
          fluid
          className="subscribe-btn w-full"
          disabled={active}
        >
          {buttonLabel || active
            ? buttonLabel || 'Current Plan'
            : upgrading
            ? 'Change Plan'
            : buttonLabel}
        </Button>
      </div>

      <div className="plan-detail">
        <h4 className="font-semibold text-md text-gray-800 mb-2">Key Features</h4>
        {plan.plan_features && (
          <FeatureGrid features={plan.plan_features.filter((f) => f.unique_feature)} />
        )}
      </div>
    </div>
  )
}

function FeatureGrid({ features }) {
  return (
    <ul className="feature-grid">
      {features.map((feature, i) => (
        <li key={`feature-${i}`} className="flex items-top text-green-500 space-x-1">
          <Check size={22} />
          <span
            className="feature-desc"
            dangerouslySetInnerHTML={{ __html: feature.html_desc }}
          />
        </li>
      ))}
    </ul>
  )
}

export default Plan
