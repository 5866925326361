import React, { Component } from 'react'

import Field from '../shared/forms/Field'
import Api from '../utils/Api'
import SuccessMessage from '../shared/SuccessMessage'

export default class MetadataUrlForm extends Component {
  state = { loading: false, url: this.props.url || '', error: null, success: null }

  render() {
    const { loading, url, error, success } = this.state
    return (
      <div>
        <Field
          label="SAML iDP Metadata URL"
          className="idp-metadata disabled-value mb-2"
          note='Copy the Identity Provider metadata url from the "Sign On" tab on the Sharesecret SAML'
          name="url"
          type="text"
          loading={loading}
          value={url}
          error={error}
          button="Save"
          buttonDisabled={this.props.url === url}
          onSubmit={this.saveMetadataUrl}
          onChange={(e) => this.setState({ url: e.target.value })}
        />
        {success && <SuccessMessage message={success} />}
      </div>
    )
  }

  saveMetadataUrl = (e) => {
    e.preventDefault()
    const { url } = this.state
    if (!url || url === '') {
      return
    }
    this.setState({ loading: true })
    Api.util
      .post('/organization/metadata_url', { url })
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
          success: 'The identity provider metadata has been successfully updated.',
        })
      })
      .catch((err) => {
        if (err.response) {
          const body = err.response.body
          this.setState({ loading: false, error: body.error })
        } else {
          this.setState({ loading: false, error: 'An unexpected error occured.' })
        }
      })
  }
}
