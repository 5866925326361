/**
 * Returns the user lmiit depending on the type of plan.
 * Legacy plans specify a hard user limit. Newer licensed
 * subscriptions are a per-seat license.
 *
 * @param {Object} subscription
 */
export function userLimit(subscription) {
  const plan = subscription && subscription.plan
  if (!plan) return 5 // trial hack
  return plan.licensed ? subscription.quantity : plan.user_limit
}
