import React from 'react'

export default function ErrorMessage(props) {
  return <NewErrorMessage>{props.content}</NewErrorMessage>
}

export function NewErrorMessage({ error, errors, children }) {
  return (
    <div className="rounded-md bg-red-50 p-4 shadow-md border border-red-300">
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="text-red-700 text-base">
          {children}
          {error ||
            (errors && (
              <div>
                {error && <div>{error}</div>}
                {errors && errors.map((err) => <div key={err}>{err}</div>)}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
