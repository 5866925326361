import React from 'react'
import PropTypes from 'prop-types'
import { CreditCard } from 'heroicons-react'

const CardDisplay = ({ card, onClickChange }) => {
  const { brand, last4, exp_year, exp_month } = card

  return (
    <div className="flex items-center text-base text-gray-500 card-display">
      <CreditCard className="text-gray-700" />
      <span style={{ paddingRight: 16, paddingLeft: 8 }}>{brand}</span>
      <span style={{ paddingRight: 16 }}>•••• •••• •••• {last4}</span>
      <span style={{ paddingRight: 16 }}>
        {exp_month} / {exp_year}
      </span>
      <div style={{ flexGrow: 2, textAlign: 'right' }}>
        {onClickChange && (
          <a style={{ paddingLeft: '1em' }} href="#" onClick={onClickChange}>
            Update Card
          </a>
        )}
      </div>
    </div>
  )
}

CardDisplay.propTypes = {
  card: PropTypes.object.isRequired,
  onClickChange: PropTypes.func,
}

export default CardDisplay
