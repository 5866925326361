import React from 'react'

export default function Select({ options, className, ...rest }) {
  let classes =
    'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md py-3'
  if (className) {
    classes += ` ${className}`
  }
  return (
    <select className={classes} {...rest}>
      {options.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </select>
  )
}
