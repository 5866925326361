import React from 'react'

export default function Checkbox({ ...props }) {
  return (
    <input
      type="checkbox"
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded disabled:opacity-50"
      {...props}
    />
  )
}
