import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'

import Api from '../utils/Api'
import { Plan, PLAN_TYPES, UpgradePlan } from './'
import CardModal from './CardModal'

import './PlanPicker.scss'

const ENTERPRISE_PLAN = {
  header_class: 'enterprise blue',
  name: 'Enterprise',
  description:
    'For large orgs that need unlimited users, SLAs, premium support and other custom functionality.',
  buttonLabel: '',
  plan_features: [
    { html_desc: 'All enterprise features +', unique_feature: true },
    { html_desc: 'SLA', unique_feature: true },
    { html_desc: 'Unlimited users', unique_feature: true },
    { html_desc: 'Volume pricing', unique_feature: true },
    { html_desc: 'Custom deployments', unique_feature: true },
  ],
}

const SUCCESS_MESSAGES = {
  card: 'You have successfully subscribed.',
  update: 'You have successfully updated your subscription.',
}

export default class PlanPicker extends React.Component {
  state = {
    hasCard: this.props.subscription && this.props.subscription.card_details,
    showCardForm: false,
    successMessage: null,
    loading: false,
    plan: null,
    subscription: this.props.subscription,
    planType: PLAN_TYPES.full,
  }

  static defaultProps = {
    buttonLabel: 'Upgrade Now',
  }

  render() {
    const { showCardForm, successMessage, subscription } = this.state
    const { plans, maus, trial, buttonLabel } = this.props

    return (
      <div className="plan-picker">
        {showCardForm && this.upgradeForm()}
        {successMessage && this.showSuccessMessage()}

        {trial && maus > 0 && (
          <div className="maus">
            Your total users during the trial was <strong>{maus} people</strong>.
          </div>
        )}

        <div className="plans monthly">
          {plans.map((plan, i) => (
            <Plan
              key={plan._id}
              plan={plan}
              maus={maus}
              buttonLabel={buttonLabel}
              upgrading={!!subscription}
              active={
                !subscription.trial && plan._id === (subscription && subscription.plan_id)
              }
              onPick={this.pickPlan}
            />
          ))}
          <Plan
            plan={ENTERPRISE_PLAN}
            enterprise
            buttonLabel={'Contact Us'}
            upgrading={!!subscription}
            active={false}
            onPick={() => (window.location.href = '/contact')}
          />
        </div>
      </div>
    )
  }

  upgradeForm() {
    const { maus } = this.props
    const { plan, subscription } = this.state

    return (
      <CardModal header={`Upgrade Plan - ${plan.name}`} onClose={this.closeCardModal}>
        <UpgradePlan
          plan={plan}
          subscription={subscription}
          maus={maus}
          onFinish={this.finishedSubscription}
        />
      </CardModal>
    )
  }

  showSuccessMessage() {
    return (
      <Message positive onDismiss={this.dismissSuccess}>
        {this.state.successMessage}
      </Message>
    )
  }

  /**
   * Called after the user's card is tokenized when subscribing and adding a card for the first time
   */
  finishedSubscription = (subscription) => {
    this.setState({ hasCard: true, subscription }, this.hideCardForm)
  }

  saveSubscription = (params, cb) => {
    let data = { plan_id: this.state.plan._id }
    data = Object.assign(data, params || {})

    this.setState({ loading: true }, () => {
      Api.Subscription.update(data)
        .then((response) => {
          this.setState({ loading: false, subscription: response.body.subscription }, cb)
        })
        .catch((err) => {
          this.setState({ loading: false })
          console.error(err)
        })
    })
  }

  pickPlan = (plan) => {
    const { pricingPage } = this.props
    pricingPage ? (window.location.href = '/signup') : this.showCardForm(plan)
  }

  closeCardModal = () => this.setState({ showCardForm: false })
  dismissSuccess = () => this.setState({ successMessage: null })
  hideCardForm = (cb) =>
    this.setState({
      showCardForm: false,
      plan: null,
      successMessage: SUCCESS_MESSAGES.card,
    })
  showCardForm = (plan) => this.setState({ showCardForm: true, plan })
}

PlanPicker.propTypes = {
  subscription: PropTypes.object,
  plans: PropTypes.array.isRequired,
  maus: PropTypes.number,
  buttonLabel: PropTypes.string,
  pricingPage: PropTypes.bool.isRequired,
}
