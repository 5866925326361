String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.titleize = function() {
  var string_array = this.replace(/_/g, ' ').split(' ');
  string_array = string_array.map(function(str) {
     return str.length <= 2 ? str.toUpperCase() : str.capitalize();
  });

  return string_array.join(' ');
}
