import React, { useState } from 'react'
import copy from 'copy-to-clipboard'
import styled from 'styled-components'
import { Copy } from 'react-feather'

import { VerifyAuthCode } from './VerifyAuthCode'
import { Field } from '../shared/Field'
import { PurpleButton, CopyButton, PurpleButtonLink } from '../shared/Button'

const CodeTag = styled.span`
  background-color: #f0f2f6;
  color: #4c517f;
  font-family: monospace;
  padding: 0.4em 0.6em;
`

const GuestRegWrap = styled.div`
  color: #3b3c44;

  p {
    line-height: 1.65em;
    font-size: 15px;
  }
`

export default function MFARegistration({ otpSecretKey, email, qrImageUrl }) {
  const [copied, setCopied] = useState(false)
  const [showVerify, setShowVerify] = useState(false)
  const [secretUrl, setSecretUrl] = useState(null)

  const handleClickCopy = () => {
    copy(otpSecretKey)
    setCopied(true)
  }

  const handleSuccess = (res) => {
    setShowVerify(false)
    setSecretUrl(res.body.redirect_to)
  }

  if (showVerify) return <VerifyAuthCode mode="mfa" onSuccess={handleSuccess} />
  else if (secretUrl) {
    return (
      <div>
        <p className="mb-4">
          You have successfully authenticated. You may now securely access the secret.
        </p>
        <PurpleButtonLink href={secretUrl}>Continue to secret</PurpleButtonLink>
      </div>
    )
  }

  return (
    <GuestRegWrap>
      <p className="mb-4">
        Register a multi-factor authentication device with Google Authenticator or 1Password
        by scanning the QR code below.
      </p>

      <div style={{ textAlign: 'center' }}>
        <form>
          <Field name="email" label="Email Address" disabled value={email} />
        </form>
        <img className="mx-auto" src={qrImageUrl} />

        <p style={{ color: '#3B3C44', fontSize: 16, fontWeight: 600, marginTop: 6 }}>
          Or enter the following code manually:
        </p>

        <div
          onClick={handleClickCopy}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CodeTag>{otpSecretKey}</CodeTag>
          <CopyButton style={{ marginLeft: 10 }}>
            <Copy size={20} style={{ marginRight: '6px' }} />
            <span style={{ fontWeight: 700, textTransform: 'uppercase' }}>
              {copied ? 'Copied!' : 'Copy'}
            </span>
          </CopyButton>
        </div>

        <PurpleButton className="mt-4 mx-auto w-full" onClick={() => setShowVerify(true)}>
          Next
        </PurpleButton>
      </div>
    </GuestRegWrap>
  )
}
