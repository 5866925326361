import React from 'react'
import Modal from '../shared/Modal'

import './CardModal.scss'

const CardModal = ({ header, children, size, onClose }) => (
  <Modal
    size={size}
    open
    closeIcon
    onClose={onClose}
    className="plan-picker-modal"
    header={header}
  >
    {children}
  </Modal>
)

export default CardModal
