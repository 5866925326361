import React from 'react'
import { Api, ErrorReporter } from '../utils/'
import RelativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import ErrorMessage from '../shared/ErrorMessage'

dayjs.extend(RelativeTime)

class DecryptSecretForm extends React.Component {
  state = { secret: null, locked: true, error: null }

  onClickButton = (e) => this.unlockSecret()

  unlockSecret() {
    Api.Secret.decrypt(this.props._id)
      .then((res) => {
        this.setState({ secret: res.body.secret, locked: false }, () =>
          this.textarea.focus()
        )
      })
      .catch((err) => {
        if (err.status === 400) { // issue with the password not being present
          this.setState({error: "Your secret URL is malformed. Please ask the sender to share the full secret URL with you."})
        } else {
          ErrorReporter.report(err)
        }
      })
  }

  secretUnlocked = (data) => {
    this.setState({ secret: data.secret, locked: false })
    this.textarea.focus()
  }

  render() {
    const { error } = this.state
    return (
      <div>
        <div className="secret-header">
          <h1 className="foreground-color">Unlock Secret</h1>

          <div className="shared-by">Shared {dayjs(this.props.created_at).fromNow()}</div>
        </div>

        {error && <div className="mb-4"><ErrorMessage content={error} /></div>}
        <div>
          <div className={`secret ${this.state.locked && 'locked'}`}>
            <i className="fa fa-lock" />
            <textarea
              name="decrypted-secret"
              className="secret-content"
              value={this.state.secret || ''}
              data-uneditable
              ref={(input) => {
                this.textarea = input
              }}
            />
          </div>
          {this.unlockButton()}
        </div>

        <div className="destroy-note">{this.secretAccessSummary()}</div>
      </div>
    )
  }

  secretAccessSummary() {
    var secret = this.props
    if (secret.expired) {
      return 'This secret is expired.'
    }
    if (secret.max_accesses) {
      var remaining = secret.max_accesses - secret.num_accesses
      return `This secret can be accessed ${remaining} more times`
    } else {
      var expires_in = dayjs(secret.expires_at).fromNow()
      return `This secret expires ${expires_in}.`
    }
  }

  unlockButton() {
    var secret = this.props
    const opts = {
      name: 'button',
      className: 'btn-unlock btn-primary foreground-color',
      type: 'submit',
      onClick: this.onClickButton,
    }
    if (secret.expired) {
      return (
        <button {...opts} disabled="disabled">
          Expired
        </button>
      )
    } else {
      return (
        <button {...opts}>
          <i className="fa fa-lock" /> Unlock
        </button>
      )
    }
  }
}

export default DecryptSecretForm
