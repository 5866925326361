import React from 'react'
import PropTypes from 'prop-types'
import { CheckCircleOutline } from 'heroicons-react'

import { CardForm } from '.'
import CardDisplay from './CardDisplay'
import Api from '../utils/Api'
import { NewErrorMessage } from '../shared/ErrorMessage'
import stripeLogo from './powered_by_stripe.svg'
import { Label } from '../shared/Field'
import { PurpleButton as Button } from '../shared/Button'

import './UpgradePlan.scss'

const INVALID_QUANTITY_ERROR = 'Quantity is less than the minimum # of users.'

export default class UpgradePlan extends React.Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    subscription: PropTypes.object,
    onFinish: PropTypes.func, // successfully saved
    maus: PropTypes.number,
  }

  state = {
    billingPeriod: 'monthly',
    quantity: this.props.maus || this.props.plan.min_users || 1,
    loading: false,
    error: null,
    success: false,
    subscription: null,
    quantityError: null,
  }

  cardForm = null

  render() {
    const { plan, subscription } = this.props
    const { billingPeriod, loading, quantity, error, success, quantityError } = this.state
    const card = subscription && subscription.card_details
    const minUsers = plan.min_users

    const price = billingPeriod === 'annual' ? plan.annual_price : plan.price
    const dollarPrice = `$${price / 100}`
    const quantityTotal = quantity * price
    const total = quantity >= minUsers ? quantityTotal : minUsers * price
    const periodLabel = billingPeriod === 'annual' ? 'year' : 'month'
    const isValid = quantity >= minUsers

    if (success) {
      return this.showSuccess()
    }

    return (
      <div className="space-y-6 upgrade-plan">
        {error && <NewErrorMessage>{error}</NewErrorMessage>}
        <div className="field-group">
          <div className="plan-details">
            <div>
              <div className="flex items-center space-x-3">
                <input
                  type="number"
                  name="quantity"
                  className="block border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  value={quantity}
                  style={{ width: 80 }}
                  onChange={this.changeQuantity}
                />
                <div className="flex items-center space-x-2 text-gray-700">
                  <span className="font-medium">users</span>
                  <span>x</span>
                  <span style={{}}>{dollarPrice}</span>
                  <span className="text-sm" style={{ color: '#8895B3' }}>
                    / {periodLabel}
                  </span>
                </div>
                <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
                  <img src={stripeLogo} />
                </div>
              </div>
              {minUsers && (
                <div className="note">
                  <div className="min-price">
                    *A minimum of {minUsers} users is required for this plan.
                  </div>
                </div>
              )}
              {quantityError && (
                <div className="error-messages" style={{ paddingLeft: '1em' }}>
                  {quantityError}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center mt-4 space-x-6 field">
          <div className="flex items-center">
            <input
              name="billingPeriod"
              id="billingPeriodMonthly"
              type="radio"
              className="w-4 h-4 mr-1.5 text-indigo-600 border-gray-300 cursor-pointer focus:ring-indigo-500"
              value="monthly"
              checked={billingPeriod === 'monthly'}
              onChange={this.changedBillingPeriod}
            />
            <Label style={{ paddingRight: 0, fontSize: 15 }} htmlFor="billingPeriodMonthly">
              Monthly
            </Label>
          </div>
          <div className="flex items-center">
            {plan.annual_plan && (
              <React.Fragment>
                <input
                  name="billingPeriod"
                  type="radio"
                  id="billingPeriodAnnual"
                  className="w-4 h-4 mr-1.5 text-indigo-600 border-gray-300 cursor-pointer focus:ring-indigo-500"
                  value="annual"
                  checked={billingPeriod === 'annual'}
                  onChange={this.changedBillingPeriod}
                />
                <Label
                  className="mr-2"
                  style={{ fontSize: 15 }}
                  htmlFor="billingPeriodAnnual"
                >
                  Annual
                </Label>
                <span
                  className="font-semibold leading-none text-green-600"
                  style={{
                    textTransform: 'uppercase',
                    fontSize: 13,
                  }}
                >
                  2 months free!
                </span>
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="field-group">
          <div className="plan-details">
            {card ? (
              <CardDisplay card={card} />
            ) : (
              <CardForm
                inline
                hideSubmit
                ref={(e) => (this.cardForm = e)}
                onError={this.handleTokenizationError}
                onFinish={this.updateSubscription}
              />
            )}
          </div>
        </div>

        <hr />

        <div>
          <div className="text-xl font-semibold">
            <span className="pr-2 tracking-tight text-gray-700">Due Today </span>
            <span style={{ color: '#21A951' }}>${total / 100}</span>
          </div>
        </div>

        <Button
          onClick={this.submit}
          loading={loading}
          disabled={!isValid}
          className="w-full my-4"
        >
          Upgrade Plan
        </Button>

        <p className="italic disclaimer">
          You agree to be billed for any additional users above the quantity specified
          according to the rate and billing terms specified here.
        </p>
      </div>
    )
  }

  clickedChangeCard = (e) => {
    e.preventDefault()
  }

  isValidQuantity = () => {
    const { plan } = this.props
    const { quantity } = this.state

    if (!plan.min_users) return true
    return quantity >= plan.min_users
  }

  changeQuantity = (e) => {
    const {
      plan: { min_users },
    } = this.props
    const value = e.target.value

    let state = { quantity: value, quantityError: null }
    if (min_users && value < min_users) {
      state.quantityError = INVALID_QUANTITY_ERROR
    }
    this.setState(state)
  }

  showSuccess() {
    return (
      <div className="upgrade-plan">
        <div>
          <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
            {/* <CheckIcon className="w-6 h-6 text-green-600" aria-hidden="true" /> */}
            <CheckCircleOutline className="w-6 h-6 text-green-600" aria-hidden="true" />
          </div>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="text-lg font-medium leading-6 text-gray-900">
            Upgrade Successful
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Your subscription has been successfully updated.
            </p>
          </div>
          <div className="flex justify-center">
            <Button onClick={this.close} className="mt-4 font-semibold">
              Close
            </Button>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Close this form.
   */
  close = () => {
    const { onFinish } = this.props
    const { subscription } = this.state
    onFinish && onFinish(subscription)
  }

  handleTokenizationError = (error) => {
    if (error.message) {
      this.setState({ loading: false, error: error.message })
    }
  }

  /**
   * Event handler when the user clicks the submit button
   */
  submit = () => {
    const subscription = this.props.subscription

    if (!this.isValidQuantity()) {
      return this.setState({ quantityError: INVALID_QUANTITY_ERROR })
    }

    this.setState({ loading: true }, () => {
      if (subscription && subscription.card_details) {
        // if already card on file
        this.updateSubscription()
      } else {
        // saving a new card
        this.cardForm.tokenizeCard()
      }
    })
  }

  /**
   * Update the remote subscription object.
   * @param {String} token only passed when the card doesn't already exist.
   */
  updateSubscription = (token) => {
    const { plan } = this.props
    const { quantity, billingPeriod } = this.state
    const data = { plan_id: plan._id, token, quantity, term: billingPeriod }

    this.setState({ loading: true }, () => {
      Api.Subscription.update(data)
        .then(({ body }) => {
          this.setState({ loading: false, subscription: body.subscription, success: true })
        })
        .catch((err) => {
          const response = err.response
          if (response.statusCode === 500) {
            return this.setState({
              loading: false,
              error:
                'An unexpected error occurred. Please try again, or contact our support team.',
            })
          }

          if (response.body && response.body.errors) {
            this.setState({ loading: false, error: response.body.errors[0] })
          } else {
            this.setState({ loading: false, error: err.errors })
          }

          console.error(err)
        })
    })
  }
  changedBillingPeriod = (e) => this.setState({ billingPeriod: e.target.value })
}
