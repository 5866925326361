import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Form } from 'semantic-ui-react'
import emailValidator from 'email-validator'

import { Api, ErrorReporter } from '../utils/'
import { userLimit } from '../utils/subscription'
import { SuccessMessage, FormErrors } from '../shared'
import { Field, Label } from '../shared/Field'
import Select from '../shared/Select'

export default class InviteForm extends Component {
  static propTypes = {
    roles: PropTypes.array.isRequired,
    subscription: PropTypes.object,
    orgUsers: PropTypes.number,
  }

  state = {
    name: '',
    email: '',
    role: '',
    saving: false,
    errors: null,
    success: null,
    orgUsers: this.props.orgUsers,
  }

  atUserLimit() {
    const { subscription } = this.props
    const { orgUsers } = this.state
    const plan = subscription && subscription.plan
    if (!plan.user_limit) return false
    const limit = userLimit(subscription)
    return plan && orgUsers >= limit
  }

  changeSelect = (e) => this.setState({ role: e.target.value })

  isValid() {
    const { name, email, role } = this.state
    return name !== '' && emailValidator.validate(email) && role !== ''
  }

  render() {
    const { role, saving, errors, success } = this.state
    const { roles, subscription } = this.props
    const roleOptions = roles.map((role) => ({
      key: role,
      value: role,
      label: role.capitalize(),
    }))
    const submitDisabled = !this.isValid() || saving
    const atUserLimit = this.atUserLimit()

    return (
      <div className="main-content-invite-form">
        <Form onSubmit={this.submitForm} error={!!errors} disabled={atUserLimit}>
          {success && <SuccessMessage message={success} />}

          <div className="form-row">
            <div className="grid grid-cols-4 gap-x-4 items-end">
              {this.field('name')}
              {this.field('email', 'email')}
              <div>
                <Label>Role</Label>
                <Select
                  value={role}
                  name="role"
                  disabled={atUserLimit}
                  onChange={this.changeSelect}
                  options={roleOptions}
                  placeholder="User"
                />
              </div>
              <div>
                <Button
                  content="Invite"
                  loading={saving}
                  disabled={submitDisabled || atUserLimit}
                  className="bg-indigo-700 text-white rounded-md px-8 py-3 border border-transparent shadow-sm text-base font-medium hover:bg-indigo-800"
                />
              </div>
            </div>
          </div>
        </Form>
        {atUserLimit && (
          <FormErrors>
            You have used all remaining user accounts. Please{' '}
            <a href="/subscription">upgrade your account</a> to invite more users.
          </FormErrors>
        )}
        {errors && <FormErrors errors={errors} />}
      </div>
    )
  }

  field(name, type = 'text') {
    const errors = this.state.errors && this.state.errors[name]
    const label = name.capitalize()
    const atUserLimit = this.atUserLimit()

    return (
      <Field
        label={label}
        placeholder={label}
        disabled={atUserLimit}
        name={name}
        onChange={(val) => this.setState({ [name]: val })}
        value={this.state[name]}
        type={type}
        error={!!errors}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md py-3"
      />
    )
  }

  submitForm = (e) => {
    e.preventDefault()
    const { name, email, role } = this.state

    this.setState({ saving: true }, () => {
      Api.User.invite({ name, email, role })
        .then((res) => {
          this.setState({
            orgUsers: this.state.orgUsers + 1,
            saving: false,
            name: '',
            email: '',
            role: '',
            errors: null,
            success: `User invited successfully.`,
          })
        })
        .catch((err) => {
          ErrorReporter.report(err)
          if (err.response && err.response.body && err.response.body.errors) {
            return this.setState({ saving: false, errors: err.response.body.errors })
          }

          // 500 error
          return this.setState({
            saving: false,
            errors: 'Sorry, an unexpected error occurred.',
          })
        })
    })
  }
}
