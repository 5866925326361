import Bugsnag from '@bugsnag/js'

export const report = (err, metadata) => {
  let response = {
    status: null,
    body: null,
  }

  if (err && err.response) {
    const { body, status } = err.response
    response.status = status
    response.body = body
  }

  Bugsnag.notify(err, (event) => {
    event.addMetadata('response', response)
    event.addMetadata('extra', metadata)
  })
}

export default {
  report,
}
