import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'

import CardForm from './CardForm'
import CardDisplay from './CardDisplay'
import Api from '../utils/Api'

export default class BillingSettings extends React.Component {
  state = {updateCard: null, success: false, card: this.props.card}

  render() {
    const { updateCard, card, success } = this.state

    return (
      <div style={{maxWidth: '50%'}}>
        {success && this.showSuccess()}
        <div>
          {card
            ? <CardDisplay
              card={card}
              onClickChange={this.clickedChangeCard}
            />
            : this.noCard()
          }
        </div>

        {updateCard && this.updateCardForm()}
      </div>
    )
  }

  noCard() {
    return <div className='no-card'>
      No card on file.
    </div>
  }

  showSuccess() {
    return (
      <Message positive
        onDismiss={() => this.setState({success: false})}
        content='Your card was updated successfully.' />
    )
  }

  updateCardForm() {
    return (
      <div style={{marginTop: '1em'}}>
        <h3>New Card</h3>
        <CardForm onFinish={this.saveCard}
          inline
          loading={this.state.loading} />
      </div>
    )
  }

  /**
   * Called after the card has been tokenized
   * @param {} token
   */
  saveCard = (token) => {
    Api.PaymentCard.create({token: token}).then(response => {
      this.setState({updateCard: false, success: true, card: token.card})
    }).catch(err => {
      console.error(err)
    })
  }

  clickedChangeCard = (e) => {
    e.preventDefault()

    this.setState({updateCard: true})
  }
}

BillingSettings.propTypes = {
  card: PropTypes.object
}
