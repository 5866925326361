import React, { useState } from 'react'
import times from 'lodash.times'

export default function TTLSelect({ oneTimeUse, value, ...rest }) {
  // const [expDays, setExpDays] = useState('')
  // const { organization } = this.props
  // let limit = 7
  // if (organization.ttl_limit && organization.ttl_limit !== '') {
  //   limit = organization.ttl_limit
  // }

  return (
    <select
      name="exp_days"
      className="mt-1 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      value={value}
      {...rest}
    >
      {times(7).map((n) => (
        <option key={n + 1} value={n + 1}>
          {n + 1} {n + 1 > 1 ? 'days' : 'day'}
        </option>
      ))}
    </select>
  )
}
