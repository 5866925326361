import React, { useState } from 'react'
import Api from '../utils/Api'
import { PurpleButton } from '../shared/Button'
import { Field } from '../shared/Field'

export function VerifyAuthCode({ onSuccess }) {
  const [otpCode, setOtpCode] = useState('')
  const [error, setError] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (otpCode.length !== 6) {
      setError('Please enter a 6-digit code.')
    }

    Api.util
      .post('/guests/mfa', { otp_code: otpCode })
      .then((res) => {
        console.log('res', res)
        onSuccess && onSuccess(res)
      })
      .catch((err) => {
        setError(err.response.body.error)
      })
  }

  const handleBlur = (e) => {
    setOtpCode(e.target.value)
  }

  return (
    <div>
      <p className="mb-4">
        Enter the 6 digit authentication code from your virtual MFA to complete
        registration.
      </p>

      <form onSubmit={handleSubmit} style={{ display: 'grid', rowGap: '1em' }}>
        <Field
          className="mb-2"
          name="otp_code"
          type="tel"
          value={otpCode}
          error={error}
          label="6-digit Code"
          onBlur={handleBlur}
          placeholder="123456"
        />
        <PurpleButton onClick={handleSubmit} type="submit">
          Complete Registration
        </PurpleButton>
      </form>
    </div>
  )
}
