import React, { useState } from 'react'
import Api from '../utils/Api'

import { PurpleButton } from '../shared/Button'
import { Field } from '../shared/Field'
import SuccessMessage from '../shared/SuccessMessage'

export default function GuestConfirmation({ email }) {
  const [otpCode, setOtpCode] = useState('')
  const [sentCode, setSentCode] = useState(false)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (otpCode.length !== 6) {
      setError('Please enter a 6-digit code.')
    }

    Api.util
      .post('/guests/confirm', { otp_code: otpCode })
      .then((res) => {
        window.location.href = '/guests/mfa'
      })
      .catch((err) => {
        setError(err.response.body.error)
      })
  }

  const resendCode = (e) => {
    e.preventDefault()
    setLoading(true)
    Api.util.post('/guests/resend-code').then((res) => {
      setLoading(false)
      setSentCode(true)
      setMessage('Check your email for the 6-digit code and enter it below.')
    })
  }

  const blurredOtp = (e) => {
    setOtpCode(e.target.value)
  }

  return (
    <div>
      {message && (
        <div className="mb-4">
          <SuccessMessage title="We emailed you a confirmation code." message={message} />
        </div>
      )}
      <p className="mb-4">
        To confirm you are the owner of this email address, we need to email you a
        confirmation code.
      </p>

      <form onSubmit={handleSubmit} style={{ display: 'grid', rowGap: '1em' }}>
        {sentCode && (
          <Field
            name="otp_code"
            type="tel"
            value={otpCode}
            error={error}
            label="6-digit Code"
            onBlur={blurredOtp}
            placeholder="123456"
          />
        )}
        {!sentCode && <Field name="email" label="Email Address" disabled value={email} />}
        <PurpleButton
          onClick={sentCode ? handleSubmit : resendCode}
          type="submit"
          disabled={loading}
        >
          {sentCode ? 'Confirm Account' : 'Send Confirmation Code'}
        </PurpleButton>
        {sentCode && (
          <div style={{ textAlign: 'center' }}>
            <a href="#" onClick={resendCode}>
              Resend Code
            </a>
          </div>
        )}
      </form>
    </div>
  )
}
