import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Formik, Field, ErrorMessage, useField } from 'formik'
import * as yup from 'yup'
import { FieldNote, Label, FieldError } from '../shared/Field'
import { ErrorReporter } from '../utils/'
import { PurpleButton } from '../shared/Button'
import TTLSelect from '../shared/TTLSelect'
import Api from '../utils/Api'
import Card from '../shared/Card'
import SuccessMessage from '../shared/SuccessMessage'
import { NewErrorMessage } from '../shared/ErrorMessage'

const schema = yup.object().shape({
  email: yup.string().email().required(),
  title: yup.string().required(),
  description: yup.string(),
  exp_days: yup.number().required(),
})

export default function SecretRequestForm() {
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState(null)

  const handleSubmit = (values, formik) => {
    Api.util
      .post('/secret-requests', values)
      .then((res) => {
        formik.setSubmitting(false)
        setSuccess(true)
      })
      .catch((err) => {
        if (err && err.response && err.response.body) {
          setErrors(Object.values(err.response.body.errors).flat())
        } else {
          ErrorReporter.report(err)
        }
      })
  }

  const initial = {
    email: '',
    title: '',
    exp_days: 5,
    description: '',
  }

  return (
    <div>
      <Card>
        {errors && (
          <div className="mb-4">
            <NewErrorMessage errors={errors} />
          </div>
        )}
        {success && (
          <div>
            <SuccessMessage
              title="Secret Request Created Successfully"
              message="The secret request has been emailed to the recipient. You will receive an email notification when the recipient responds to the request."
            />
          </div>
        )}
        {!success && (
          <Formik
            onSubmit={handleSubmit}
            validationSchema={schema}
            initialValues={initial}
            validateOnChange={false}
            validateOnBlur
          >
            {(props) => {
              return (
                <Form autoComplete="off" style={{ display: 'grid', rowGap: '1em' }}>
                  <p className="text-gray-800 my-0" style={{ lineHeight: '1.6em' }}>
                    Submitting this form will email the person a link to a secret form where
                    they can enter secret information securely. You'll receive an email
                    containing a link to the secret when they've completed the request.
                  </p>
                  <ConnectedField
                    name="email"
                    label="Recipient Email Address"
                    type="email"
                    autoComplete="off"
                    placeholder="bill.lumberg@inotech.com"
                    note="We’ll email the secret request to this person."
                  />
                  <ConnectedField
                    name="title"
                    label="Request Title"
                    type="text"
                    placeholder="A short title describing the secret"
                    note="A short title describing what you need from the recipient."
                  />

                  <VertFieldWrap>
                    <div>
                      <Label style={{ marginBottom: 8 }}>Expires In</Label>
                      <TTLSelect name="exp_days" value={props.values.exp_days} />
                    </div>
                  </VertFieldWrap>

                  <ConnectedField
                    name="description"
                    label="Request Description"
                    as="textarea"
                    style={{ minHeight: 100 }}
                    placeholder="Describe what information you need from the person you’re sending a secret request to. Be specific."
                    note="We’ll show this to the secret request recipient. Use it as helpful guidance so they know what you’re requesting from them."
                  />
                  <PurpleButton
                    loading={props.isSubmitting}
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    Submit Secret Request
                  </PurpleButton>
                </Form>
              )
            }}
          </Formik>
        )}
      </Card>
    </div>
  )
}

const VertFieldWrap = styled.div`
  display: grid;
  row-gap: 1em;
`

function ConnectedField({ name, label, note, input, ...rest }) {
  const [field, meta, helpers] = useField(name)
  const { error, touched } = meta

  return (
    <VertFieldWrap>
      <div>
        <Label style={{ marginBottom: 6 }}>{label}</Label>
        <Field
          name={name}
          error={touched && error}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md py-3"
          {...rest}
        />
        {note && <FieldNote style={{ marginTop: 8 }}>{note}</FieldNote>}
      </div>

      {error && touched && (
        <FieldError>
          <ErrorMessage name={name} />
        </FieldError>
      )}
    </VertFieldWrap>
  )
}
