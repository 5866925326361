import React from 'react'
import PropTypes from 'prop-types'
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements'
import { PurpleButton as Button } from '../shared/Button'

import './CardForm.scss'

class CardForm extends React.Component {
  static propTypes = {
    onFinish: PropTypes.func.isRequired,
    onError: PropTypes.func,
  }

  state = { loading: false }

  render() {
    const { loading } = this.state
    const { hideSubmit, inline } = this.props

    return (
      <form onSubmit={this.onSubmit} className={`card-form ${inline && 'inline'}`}>
        {inline ? (
          <CardElement />
        ) : (
          <div>
            <div>
              <div className="card-number-field">
                <label>Card Number</label>
                <CardNumberElement />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 20 }}>
                <label>Expiration Date</label>
                <CardExpiryElement />
              </div>
              <div className="cvc">
                <label>Security Code</label>
                <CardCVCElement />
              </div>
            </div>
          </div>
        )}
        {!hideSubmit && (
          <Button loading={loading} content={this.props.buttonTitle || 'Save Card'} />
        )}
      </form>
    )
  }

  onSubmit = (e) => {
    e.preventDefault()

    this.setState({ loading: true }, this.tokenizeCard)
  }

  /**
   * Tokenize the card data with stripe, then send it to the server.
   */
  tokenizeCard = () => {
    const { stripe, onError } = this.props

    stripe.createToken().then((response) => {
      const { token, error } = response

      if (error) {
        console.error('tokenization error', error)
        onError && onError(error)
      } else {
        this.setState({ token }, this.saveSubscription)
      }
    })
  }

  saveSubscription = () => this.props.onFinish(this.state.token)
}

const WrappedCardForm = injectStripe(CardForm, { withRef: true })

class StripeWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.cardForm = null
  }

  render() {
    const props = this.props
    return (
      <StripeProvider apiKey={window.STRIPE_KEY}>
        <Elements>
          <WrappedCardForm ref={(e) => (this.cardForm = e)} {...props} />
        </Elements>
      </StripeProvider>
    )
  }

  tokenizeCard = () => {
    this.cardForm.getWrappedInstance().tokenizeCard()
  }
}

StripeWrapper.propTypes = {
  onFinish: PropTypes.func.isRequired,
}

export default StripeWrapper
